import 'hammerjs';
import { APP_INITIALIZER, enableProdMode, importProvidersFrom } from '@angular/core';

import {
  RemixIconModule,
  RiArrowLeftSLine,
  RiArrowRightSLine,
  RiAttachmentLine,
  RiChat3Line,
  RiCloseLine,
  RiHome2Line,
  RiMovieLine,
  RiSendPlane2Line,
  RiSubtractLine,
  RiThumbDownFill,
  RiThumbDownLine,
  RiThumbUpFill,
  RiThumbUpLine,
  RiWindow2Line,
} from 'angular-remix-icon';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { FileUploadModule } from 'ng2-file-upload';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { ClickOutsideModule } from '@solidexpert/ng-click-outside';
import { AutosizeModule } from 'ngx-autosize';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { provideAnimations } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { bootstrapApplication, BrowserModule } from '@angular/platform-browser';
import { DatePipe } from '@angular/common';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { RedirectService } from './app/services/redirect.service';
import { DisplayService } from './app/services/display.service';
import { AddHeaderInterceptor } from './app/services/add-header-interceptor';
import { AppComponent } from './app/app.component';
import { environment } from './environments/environment';
import { httpLoaderFactory } from './app/app.module';
import { LoggerService } from './app/services/logger/logger.service';

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      BrowserModule,
      FormsModule,
      NgScrollbarModule,
      AutosizeModule,
      ClickOutsideModule,
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: httpLoaderFactory,
          deps: [HttpClient],
        },
        defaultLanguage: 'en',
      }),
      FileUploadModule,
      FlexLayoutModule,
      NgxSkeletonLoaderModule,
      RemixIconModule.configure({
        RiChat3Line,
        RiWindow2Line,
        RiArrowLeftSLine,
        RiArrowRightSLine,
        RiThumbDownLine,
        RiThumbDownFill,
        RiThumbUpLine,
        RiThumbUpFill,
        RiCloseLine,
        RiHome2Line,
        RiSubtractLine,
        RiAttachmentLine,
        RiSendPlane2Line,
        RiMovieLine,
      }),
    ),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AddHeaderInterceptor,
      multi: true,
    },
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideFirestore(() => getFirestore()),
    DisplayService,
    RedirectService,
    DatePipe,
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi()),
    LoggerService,
    {
      provide: APP_INITIALIZER,
      useFactory: (configService: LoggerService) => () => configService.setLogLevel(environment.logLevel),
      deps: [LoggerService],
      multi: true,
    },
  ],
}).catch((err) => console.error(err));
