import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActorType } from 'src/app/model/user';

@Pipe({
  name: 'sanitizeMessage',
  standalone: true,
})
export class SanitizeMessagePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(html: string, actorType?: ActorType) {
    switch (actorType) {
      // Only HTML content from following messages author types have to be trusted.
      // Exception can be removed when iframe / video will be treated as specific messages types
      case ActorType.SYSTEM:
      case ActorType.BOT:
        return this.sanitizer.bypassSecurityTrustHtml(html);

      default:
        return this.sanitizer.sanitize(SecurityContext.HTML, html);
    }
  }
}
