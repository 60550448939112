import { Component, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ExtendedModule } from '@ngbracket/ngx-layout/extended';
import { NgStyle } from '@angular/common';
import { FlexModule } from '@ngbracket/ngx-layout/flex';
import { WidgetComponent } from './widget/widget.component';
import { ButtonComponent } from './button/button.component';
import { PopTriggersComponent } from './pop-triggers/pop-triggers.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [FlexModule, NgStyle, ExtendedModule, WidgetComponent, PopTriggersComponent, ButtonComponent],
})
export class AppComponent {
  title = 'widget';

  @ViewChild('widget', { static: true }) widgetComponent: WidgetComponent;

  constructor(translate: TranslateService) {
    translate.setDefaultLang('en');
  }
}
