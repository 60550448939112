/* eslint-disable no-console */
import { Injectable } from '@angular/core';

export const LOG_LEVELS = ['silent', 'error', 'warn', 'info', 'debug'] as const;
export type LogLevel = (typeof LOG_LEVELS)[number];

/**
 * Global logger service that can enable or disable console logging
 * in the browser environment.
 */
@Injectable({
  providedIn: 'root',
})
export class LoggerService {
  private logLevel: LogLevel = 'debug';

  getLogLevel(): LogLevel {
    return this.logLevel;
  }

  getWindow(): any {
    return window as any;
  }

  getDevToolsLevel(): any {
    return this.getWindow()?.botmindLogLevel as LogLevel;
  }

  setLogLevel(level: LogLevel | string): void {
    const targetIndex = LOG_LEVELS.indexOf(level as LogLevel);
    if (targetIndex !== -1) {
      this.logLevel = LOG_LEVELS[targetIndex];
    } else {
      console.warn(`Invalid "${level}" log level set, using instead: "${this.logLevel}"`);
    }
  }

  isAuthorized(level: LogLevel): boolean {
    let currentLevel = this.logLevel;

    // Open the opportunity to set the log level via this hook at runtime in production.
    const devToolsLevel = this.getDevToolsLevel();
    if (devToolsLevel) {
      currentLevel = devToolsLevel;
    }

    const currentIndex = LOG_LEVELS.indexOf(currentLevel);
    const targetIndex = LOG_LEVELS.indexOf(level);
    if (currentIndex === -1) {
      return true;
    }
    if (targetIndex === -1) {
      return true;
    }

    return targetIndex <= currentIndex;
  }

  error(...args: any[]): void {
    if (this.isAuthorized('error')) {
      console.error(...args);
    }
  }

  warn(...args: any[]): void {
    if (this.isAuthorized('warn')) {
      console.warn(...args);
    }
  }

  info(...args: any[]): void {
    if (this.isAuthorized('info')) {
      console.info(...args);
    }
  }

  debug(...args: any[]): void {
    if (this.isAuthorized('debug')) {
      console.log(...args);
    }
  }

  trace(...args: any[]): void {
    if (this.isAuthorized('debug')) {
      console.trace(...args);
    }
  }

  dir(...args: any[]): void {
    if (this.isAuthorized('debug')) {
      console.dir(...args);
    }
  }

  table(...args: any[]): void {
    if (this.isAuthorized('debug')) {
      console.table(...args);
    }
  }
}
