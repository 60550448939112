import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { NgSwitch, NgSwitchCase, NgIf } from '@angular/common';
import { FlexModule } from '@ngbracket/ngx-layout/flex';
import { SatisfactionRatingComponent } from './satisfaction-rating/satisfaction-rating.component';
import {
  FeedbackPayload,
  SatisfactionFeedbackComponent,
} from './satisfaction-feedback/satisfaction-feedback.component';

const satisfactionSteps = ['rating', 'feedback', 'end'] as const;
export type SatisfactionStep = (typeof satisfactionSteps)[number];

@Component({
  selector: 'app-satisfaction',
  templateUrl: './satisfaction.component.html',
  styleUrls: ['./satisfaction.component.scss'],
  standalone: true,
  imports: [
    FlexModule,
    NgSwitch,
    NgSwitchCase,
    SatisfactionRatingComponent,
    SatisfactionFeedbackComponent,
    NgIf,
    TranslateModule,
  ],
})
export class SatisfactionComponent implements OnInit {
  @Output() satisfactionStarted = new EventEmitter<void>();

  @Output() satisfactionNote = new EventEmitter<number>();
  @Output() skipSatisfaction = new EventEmitter<void>();
  @Output() validateFeedback = new EventEmitter<FeedbackPayload>();

  @Output() satisfactionEnded = new EventEmitter<void>();

  private hideSatisfactionAfter: number = 2500; // In milliseconds
  private currentStepSubject: Subject<SatisfactionStep> = new Subject<SatisfactionStep>();
  public currentStep: SatisfactionStep = 'rating';
  public note: number = null;

  constructor() {
    this.currentStepSubject.subscribe((step) => {
      this.currentStep = step;
    });
  }

  ngOnInit(): void {
    this.satisfactionStarted.emit();
  }

  public updateSatisfactionStep(step: SatisfactionStep): void {
    this.currentStepSubject.next(step);
  }

  public handleSatisfactionNote(note: number): void {
    this.note = note;

    this.satisfactionNote.emit(note);
    this.updateSatisfactionStep('feedback');
  }

  public handleSkipSatisfaction(): void {
    this.skipSatisfaction.emit();
    this.currentStep = null;
  }

  public handleValidateFeedback(feedback: FeedbackPayload): void {
    this.validateFeedback.emit({ comment: feedback.comment });
    this.updateSatisfactionStep('end');
  }

  public handleCloseSatisfaction(): void {
    this.satisfactionEnded.emit();
  }
}
