import { Injectable } from '@angular/core';
import { CarouselItem, CarouselItemClickableProperties } from '../model/carousel.model';
import { HtmlCarouselMessageContent } from '../model/message';
import { canParse } from '../misc/utilities';
import { LoggerService } from '../services/logger/logger.service';

@Injectable()
export class HtmlCarouselService {
  constructor(private logger: LoggerService) {}
  private domParser = new DOMParser();

  // Preserve the message content reference to allow Angular to track changes.
  private vContent: HtmlCarouselMessageContent = { message: '', items: [] };

  // Readonly for its public scope
  public get content(): HtmlCarouselMessageContent {
    return this.vContent;
  }

  public originalText: string;

  extractHtmlCarouselMessageContent(): HtmlCarouselMessageContent {
    // Only parse when really needed.
    if (HtmlCarouselService.hasHtmlList(this.originalText)) {
      const text = HtmlCarouselService.removeGptVariables(this.originalText);
      const doc = this.parseHtml(text);
      if (!doc?.body) {
        return this.content;
      }
      const carouselItems = this.extractCarouselElements(doc.body);

      // Only keep items which have at least one image.
      const validCarouselItems = carouselItems.filter((elem) => !!elem.mediaUrl);

      // Be warned that it will modify the parsed content to avoid reparsing the doc.
      const message = this.extractCarouselMessage(doc.body);
      this.content.message = message;
      this.content.items.splice(0, Number.POSITIVE_INFINITY, ...validCarouselItems);
    } else {
      this.content.message = '';
      this.content.items.splice(0);
    }
    return this.content;
  }

  extractCarouselMessage(element: HTMLElement): string {
    HtmlCarouselService.querySelectorDeleteAll(element, 'ul, ol, li, dt, dl');
    return element.innerHTML ?? '';
  }

  extractCarouselElements(element: HTMLElement): CarouselItem[] {
    const liElements = Array.from(element.querySelectorAll('li'));
    if (!liElements?.length) {
      return [];
    }

    const carouselItems = liElements.map((liElement) => this.extractCarouselItemProperties(liElement));
    return carouselItems;
  }

  extractCarouselItemProperties(element: HTMLElement): CarouselItem {
    const clickable: CarouselItemClickableProperties[] = [];
    const imageElement = element.querySelector('img');
    const aElement = element.querySelector('a');

    let url = null;
    if (canParse(aElement?.getAttribute?.('href'))) {
      url = aElement.getAttribute('href');
      clickable.push('mediaUrl', 'description');
    }

    let mediaUrl = null;
    if (canParse(imageElement?.getAttribute?.('src'))) {
      mediaUrl = imageElement.getAttribute('src');
    }

    HtmlCarouselService.querySelectorDeleteAll(element, 'img');

    return {
      mediaUrl,
      url,
      description: element.innerHTML,
      clickable,
    };
  }

  private static hasHtmlList(text: string) {
    const pattern = /<ul|<li|<ol|【carousel】/;
    return pattern.test(text);
  }

  private parseHtml(htmlString: string) {
    let doc: Document;
    try {
      doc = this.domParser.parseFromString(htmlString, 'text/html');
    } catch (error) {
      this.logger.warn(`Can't parse HTML content from:`, htmlString);
    }
    return doc;
  }

  private static querySelectorDeleteAll(doc: HTMLElement, querySelector: string): void {
    const elements = doc.querySelectorAll(querySelector);
    elements.forEach((element) => {
      if (element.parentNode) {
        element.parentNode.removeChild(element);
      }
    });
  }

  /**
   * When using GPT with retrieval, its answer may contain reference to the files used to build the answer.
   * e.g: 【0:9†source】
   *
   * We also use it to enhance streaming will receiving messags from OpenAi.
   *
   * We do not want those tokens in the answer given to our users.
   * @see https://community.openai.com/t/what-are-n-source-in-openais-assistant-api-response/513857/10
   */
  static removeGptVariables(message = '') {
    return message.replaceAll(/【.*?】/g, '');
  }
}
