import { Component, ElementRef, Input, OnDestroy, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, takeUntil, takeWhile, tap } from 'rxjs/operators';
import * as uuid from 'uuid';
import { AngularRemixIconComponent } from 'angular-remix-icon';
import { ExtendedModule } from '@ngbracket/ngx-layout/extended';
import { NgStyle, NgClass, NgIf, NgFor } from '@angular/common';
import { FlexModule } from '@ngbracket/ngx-layout/flex';
import { ButtonContent, Message, MessageType } from '../model/message';
import { BackendService } from '../services/backend.service';
import { DisplayService } from '../services/display.service';
import { PopTriggerService } from '../services/pop-triggers.service';
import { SentMessageSanitizerService } from '../services/sent-message-sanitizer.service';
import { WidgetComponent } from '../widget/widget.component';
import { PopTriggerMessageComponent } from './pop-trigger-message/pop-trigger-message.component';

const layoutGap = 20;
@Component({
  selector: 'app-pop-triggers',
  templateUrl: './pop-triggers.component.html',
  styleUrls: ['./pop-triggers.component.scss'],
  standalone: true,
  imports: [
    FlexModule,
    NgStyle,
    ExtendedModule,
    NgClass,
    NgIf,
    AngularRemixIconComponent,
    NgFor,
    PopTriggerMessageComponent,
  ],
})
export class PopTriggersComponent implements OnDestroy {
  private subs: Subscription[] = [];
  private dismissed = false;

  popMessagesRendered: boolean = false;
  popMessagesHeight: Record<number, number> = {};
  popMessagesTotalHeight: number = -1;
  loadedMessageNumberSubject: BehaviorSubject<number> = new BehaviorSubject(0);
  messagesLoaded = false;
  messages: Message[] = [];
  firstTextMessage: Message = null;
  iframeHeight$ = new Subject<number>();
  iframeWidth$ = new Subject<number>();
  messagesTotalHeight = 0;
  messageMaxWidth = 0;

  private onDestroy$ = new Subject();

  constructor(
    public popTriggerService: PopTriggerService,
    public displayService: DisplayService,
    public backendService: BackendService,
    public sentMessageSanitizerService: SentMessageSanitizerService,
  ) {
    this.subs.push(
      this.popTriggerService.messageAdded
        .pipe(
          filter((message) => message === null),
          // Message were explicitely set to null the component needs a reset.
          tap(() => this.reset()),
        )
        .subscribe(),
    );

    this.subs.push(
      this.popTriggerService.messageAdded
        .pipe(
          filter((message) => !!message),
          tap((message) => {
            if (
              !this.firstTextMessage &&
              (message.type === MessageType.TEXT || message.type === MessageType.TEXT_PAYLOAD)
            ) {
              this.firstTextMessage = message;
            }
            this.messages.push(message);
          }),
        )
        .subscribe(),
    );

    this.subs.push(
      this.iframeHeight$
        .pipe(
          filter((height) => height >= 0),
          distinctUntilChanged(),
          takeWhile(() => !this.dismissed),
          tap((height) => this.setIframeHeight(height)),
        )
        .subscribe(),
    );

    this.subs.push(
      this.iframeWidth$
        .pipe(
          filter((width) => width >= 0),
          distinctUntilChanged(),
          takeWhile(() => !this.dismissed),
          tap((width) => this.setIframeWidth(width)),
        )
        .subscribe(),
    );

    this.subs.push(
      this.displayService.isMobile$
        .pipe(
          takeUntil(this.onDestroy$),
          debounceTime(100),
          takeWhile(() => !this.dismissed),
          map(() => this.mainContainer?.nativeElement?.getBoundingClientRect?.()?.height),
          filter((height) => height > 0),
          tap((height) => this.setIframeHeight(height + layoutGap)),
        )
        .subscribe(),
    );
  }

  @Input() widget: WidgetComponent;

  @ViewChild('mainContainer', { static: false })
  mainContainer: ElementRef<HTMLDivElement>;

  @ViewChild('popMessagesContainer', { static: false })
  popMessagesContainer: ElementRef<HTMLDivElement>;

  @ViewChild('dismissContainer', { static: false })
  dismissContainer: ElementRef<HTMLDivElement>;

  @ViewChildren('popMessages')
  renderedPopMessages: QueryList<ElementRef<HTMLDivElement>>;

  /**
   * Force component values to be reset.
   *
   * It has to be called in SPA host web page when a page change
   * is triggered to reset triggers and iframe dimensions to start
   * a fresh trigger animation.
   */
  reset(): void {
    this.popMessagesRendered = false;
    this.popMessagesHeight = {};
    this.popMessagesTotalHeight = -1;
    this.messageMaxWidth = 0;
    this.messagesTotalHeight = 0;
    this.loadedMessageNumberSubject.next(0);
    this.messagesLoaded = false;
    this.displayService.setIframeHeight(0);
    this.displayService.setIframeWidth(0);
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.subs.forEach((sub) => sub?.unsubscribe?.());
  }

  public click(message: Message) {
    switch (message.type) {
      case MessageType.TEXT:
      case MessageType.TEXT_PAYLOAD:
      case MessageType.IMAGE:
        void this.displayService.openChat(true, this.widget);
        break;
      case MessageType.CAROUSEL:
        break;
      case MessageType.ATTACHMENT:
        break;
      case MessageType.BUTTONS:
      case MessageType.QUICK_REPLIES:
        break;
      default:
        break;
    }
  }

  public async clickButton({ message, item }: { message: Message; item: ButtonContent }) {
    if (item.type === 'link') {
      return;
    }
    this.popTriggerService.clearMessages();
    await this.displayService.openChat(true, this.widget);
    if (item.content) {
      await this.sendTextPayloadMessage(item.text, item.content, message);
    } else {
      await this.sendTextMessage(item.text, message);
    }
  }

  public async sendTextPayloadMessage(text: string, payload: any, message: Message) {
    const textContent = this.sentMessageSanitizerService.sanitize(text);
    const now = Date.now();
    const socketMessage: Message = {
      author: this.widget.user,
      content: {
        text: textContent,
        payload,
      },
      sentDate: now,
      updatedDate: now,
      uuid: uuid.v3(String(now), this.widget.session.id),
      type: MessageType.TEXT_PAYLOAD,
      delivered: false,
      noNotif: false,
      isWelcome: false,
      isEngage: false,
      popNotif: false,
      isButtonReply: true,
      isWelcomeEngageButtonReply: message.isEngage || message.isWelcome,
      isLastStep: false,
      schemaSatisfaction: null,
      showSchemaSatisfaction: false,
      webchatMessageId: null,
    };
    this.widget.pushMessage(socketMessage, false, false, true);
    void this.widget.setSelfCareActionButtonsDisplayedStatus(false);
    if (!this.widget.ghostMode) {
      void this.backendService.sendMessage(socketMessage, this.widget.parentPageUrl, this.widget.parentPageTitle);
    } else {
      this.widget.sendMessageGhostMode(socketMessage);
    }
    await this.widget.subscribeToSessionChange(this.widget.session.id, true);
  }

  public async sendTextMessage(text: string, message: Message) {
    const content = this.sentMessageSanitizerService.sanitize(text);
    const now = Date.now();
    const socketMessage: Message = {
      author: this.widget.user,
      content,
      sentDate: now,
      updatedDate: now,
      uuid: uuid.v3(String(now), this.widget.session.id),
      type: MessageType.TEXT,
      delivered: false,
      noNotif: false,
      isWelcome: false,
      isEngage: false,
      popNotif: false,
      isButtonReply: true,
      isWelcomeEngageButtonReply: message.isEngage || message.isWelcome,
      isLastStep: false,
      schemaSatisfaction: null,
      showSchemaSatisfaction: false,
      webchatMessageId: null,
    };
    this.widget.pushMessage(socketMessage, false, false, true);
    void this.widget.setSelfCareActionButtonsDisplayedStatus(false);
    if (!this.widget.ghostMode) {
      void this.backendService.sendMessage(socketMessage, this.widget.parentPageUrl, this.widget.parentPageTitle);
    } else {
      this.widget.sendMessageGhostMode(socketMessage);
    }
    await this.widget.subscribeToSessionChange(this.widget.session.id, true);
  }

  dismiss() {
    this.reset();
    this.popTriggerService.clearMessages();
    this.displayService.triggerDismissed();
    this.dismissed = true;
  }

  private setIframeHeight(height: number) {
    this.displayService.setIframeHeight(Math.round(height));
  }

  private setIframeWidth(width: number) {
    this.displayService.setIframeWidth(Math.round(width));
  }

  incrementLoadedMessagesNumber() {
    this.loadedMessageNumberSubject.next(this.loadedMessageNumberSubject.getValue() + 1);
    this.messagesLoaded = this.loadedMessageNumberSubject.getValue() >= this.popTriggerService.messages.length;
  }

  animationStart({
    message,
    destinationHeight,
    destinationWidth,
  }: {
    message: Message;
    destinationHeight: number;
    destinationWidth: number;
  }) {
    let height = destinationHeight;

    // Avoid sending iframe resize commands to the widget when only "desktop messages" are loaded while displayed as mobile.
    if (this.displayService.isMobileDisplay && message.uuid !== this.firstTextMessage?.uuid) {
      height = 0;
    }

    this.messagesTotalHeight += height;

    // INFO: this is done to prevent a less wider pop message from reducing the iframe's width
    if (this.messageMaxWidth < destinationWidth) {
      this.messageMaxWidth = destinationWidth;
    }

    const dismissSize = this.dismissContainer?.nativeElement?.getBoundingClientRect?.()?.height ?? 24;

    this.iframeHeight$.next(dismissSize + this.messagesTotalHeight + layoutGap);
    this.iframeWidth$.next(this.messageMaxWidth);
  }

  messageTrackByFn(index: number, item: Message) {
    return this.messages?.[index]?.uuid === item?.uuid;
  }
}
