import {
  AsyncPipe,
  LowerCasePipe,
  NgClass,
  NgFor,
  NgIf,
  NgStyle,
  NgSwitch,
  NgSwitchCase,
  NgSwitchDefault,
} from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ExtendedModule } from '@ngbracket/ngx-layout/extended';
import { FlexModule } from '@ngbracket/ngx-layout/flex';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AngularRemixIconComponent } from 'angular-remix-icon';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { BehaviorSubject, Observable } from 'rxjs';
import { finalize, pairwise, startWith, switchMap } from 'rxjs/operators';
import { v3 as uuidV3 } from 'uuid';
import { CarouselComponent } from '../carousel/carousel.component';
import { HtmlCarouselService } from '../carousel/html-carousel.service';
import { openUrl } from '../misc/utilities';
import {
  AttachmentMessageContent,
  ButtonContent,
  ButtonsMessageContent,
  HtmlCarouselMessageContent,
  ImageMessageContent,
  Message,
  MessageContent,
  MessageType,
  OrderTrackingAvailableToWithdrawData,
  OrderTrackingCollectedData,
  OrderTrackingMessageContent,
  OrderTrackingRegisteredData,
  OrderTrackingShippedData,
  OrderTrackingStatus,
  orderTrackingStatus,
  StreamedTextMessageContent,
  VideoMessageContent,
  WebviewMessageContent,
} from '../model/message';
import { SanitizeMessagePipe } from '../pipes/sanitize-message.pipe';
import { BackendService } from '../services/backend.service';
import { DisplayService } from '../services/display.service';
import { LoggerService } from '../services/logger/logger.service';
import { SentMessageSanitizerService } from '../services/sent-message-sanitizer.service';
import { ChatMode, WidgetComponent } from '../widget/widget.component';
import { TypewriterService } from './typewriter/typewriter.service';
export type SchemaSatisfaction = 'satisfied' | 'unsatisfied' | null;
export type SchemaSatisfactionPayload = {
  messageId: string;
  previousSchemaSatisfaction: SchemaSatisfaction;
  schemaSatisfaction: SchemaSatisfaction;
};

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss'],
  providers: [HtmlCarouselService, TypewriterService],
  standalone: true,
  imports: [
    FlexModule,
    NgClass,
    ExtendedModule,
    NgStyle,
    NgIf,
    NgSwitch,
    NgSwitchCase,
    NgxSkeletonLoaderModule,
    NgFor,
    ReactiveFormsModule,
    AngularRemixIconComponent,
    CarouselComponent,
    NgSwitchDefault,
    LowerCasePipe,
    TranslateModule,
    SanitizeMessagePipe,
    AsyncPipe,
  ],
})
export class MessageComponent implements OnInit {
  private currentLanguage: string = null;

  public hoverInd = -1;
  public formattedTime: string = null;

  public orderTrackingStatus = orderTrackingStatus;

  private domParser = new DOMParser();

  @Input() widget: WidgetComponent;

  public textMessage$ = new BehaviorSubject('');
  public textStreamMessage$: Observable<string>;

  messageContent: string;
  @Input() message: Message;

  private _messageLength = 0;
  @Input()
  public get messageLength(): number {
    return this._messageLength;
  }
  public set messageLength(value: number) {
    // Force Angular change detection properly so that we can take the new message length in account for streamable messages.
    if (value > this._messageLength && this.message.type === MessageType.STREAMED_TEXT) {
      const text = this.getDisplayableMessageText((this.message.content as StreamedTextMessageContent).text);
      this.textMessage$.next(text);
    }
    this._messageLength = value;
  }

  @Input() precededBySameAuthor: boolean;
  @Input() followedBySameAuthor: boolean;
  @Input() isLastMessage: boolean;
  @Input() displayAvatar: boolean;
  @Input() displayName: boolean;
  @Input() focused: boolean;
  @Input() selfCareEnabled: boolean;
  @Input() chatMode: ChatMode;
  @Input() enableSchemaSatisfaction: boolean;
  @Output() openWebview = new EventEmitter<WebviewMessageContent>();
  @Output() schemaSatisfaction = new EventEmitter<SchemaSatisfactionPayload>();
  @Output() streamed = new EventEmitter<string>();
  openImageOnClick: boolean;

  readonly viewOpenUrl = openUrl;
  public isHtmlCarousel: boolean;

  constructor(
    private logger: LoggerService,
    public displayService: DisplayService,
    public backendService: BackendService,
    public domSanitizationService: DomSanitizer,
    public translate: TranslateService,
    public htmlCarouselService: HtmlCarouselService,
    private sentMessageSanitizerService: SentMessageSanitizerService,
    private typewriterService: TypewriterService,
  ) {
    // Create a typewriter text stream effect based on Rxjs when textStreamMessage$ is filled with text.
    this.textStreamMessage$ = this.textMessage$.pipe(
      startWith(''),
      pairwise(),
      switchMap(([previous, current]) => {
        const streamingDone = (this.message.content as StreamedTextMessageContent)?.done ?? false;
        return this.typewriterService.typeEffect(previous, current, streamingDone, 500).pipe(
          // Executed on each typeEffect iteration but also on completion of the stream.
          finalize(() => {
            this.streamed.emit(this.message.uuid);
            void this.widget.scrollDown(-1000, 200);
          }),
        );
      }),
    );
  }

  public isFirstButton: boolean;
  public imageMessageContent: SafeUrl;
  public videoMessageContent: SafeUrl;

  async ngOnInit() {
    this.formattedTime = new Date(this.message.sentDate).toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit',
    });
    this.isFirstButton = this.message.type === 'buttons' && (this.message.isWelcome || this.message.isEngage);
    if (this.isLongButton) {
      this.selectButtonMessageItem = (this.message.content as ButtonsMessageContent)[0] || null;
      this.validButtonMessageItem = !!this.selectButtonMessageItem;
    }
    if (this.message.type === MessageType.IMAGE) {
      this.imageMessageContent = this.domSanitizationService.bypassSecurityTrustUrl(
        this.message.content as ImageMessageContent,
      );
      this.openImageOnClick = true;
    }
    if (this.message.type === MessageType.VIDEO) {
      this.videoMessageContent = this.domSanitizationService.bypassSecurityTrustUrl(
        this.message.content as VideoMessageContent,
      );
    }

    if (this.message.type === MessageType.ORDER_TRACKING) {
      // Each order tracking status can come with status-specific fields
      const messageContentWithStatusSpecificFields = this.getMessageContentWithStatusSpecificFields(
        this.message.content as OrderTrackingMessageContent,
      );

      this.message.content = messageContentWithStatusSpecificFields;
    }

    if (!this.currentLanguage) {
      this.currentLanguage = this.translate.currentLang;
    }
  }

  private getMessageContentWithStatusSpecificFields(
    orderTrackingMessage: OrderTrackingMessageContent,
  ):
    | OrderTrackingRegisteredData
    | OrderTrackingShippedData
    | OrderTrackingAvailableToWithdrawData
    | OrderTrackingCollectedData
    | OrderTrackingMessageContent {
    const {
      orderStatus: unparsedOrderStatus,
      orderStatusLink: unparsedOrderStatusLink,
      customStatusImageUrl: unparsedCustomStatusImageUrl = null,
    } = orderTrackingMessage;

    // Values which need parsing
    let parsedOrderStatus: OrderTrackingStatus | null = null;
    let parsedOrderStatusLink: URL | null = null;
    let parsedCustomStatusImageUrl: URL | null = null;

    // Try to parse passed value as OrderTrackingStatus
    if (orderTrackingStatus.includes(unparsedOrderStatus as OrderTrackingStatus)) {
      parsedOrderStatus = unparsedOrderStatus as OrderTrackingStatus;
    }

    // Try to parse passed value as URL
    try {
      parsedOrderStatusLink = new URL(unparsedOrderStatusLink);
    } catch (error) {
      this.logger.error(error);
    }
    try {
      parsedCustomStatusImageUrl = new URL(unparsedCustomStatusImageUrl);
    } catch (error) {
      this.logger.error(error);
    }

    const result: OrderTrackingMessageContent = {
      ...orderTrackingMessage,
      orderStatus: parsedOrderStatus ? parsedOrderStatus : unparsedOrderStatus,
      orderStatusLink: parsedOrderStatusLink ? parsedOrderStatusLink : unparsedOrderStatusLink,
      customStatusImageUrl: parsedCustomStatusImageUrl ? parsedCustomStatusImageUrl : unparsedCustomStatusImageUrl,
    };

    const isValidDate = (date: Date): boolean => {
      try {
        const parsedDeliveryDate = new Date(date);

        if (isNaN(parsedDeliveryDate.getTime())) {
          throw new Error('Invalid date');
        }

        return true;
      } catch (error) {
        return false;
      }
    };
    const dateToLocale = (_navigator: Navigator, date: Date | string): string => {
      let localizedDate: string = `${date}`;

      if (isValidDate(new Date(date))) {
        localizedDate = new Date(date).toLocaleString(_navigator.language, {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        });
      }

      return localizedDate;
    };

    switch (orderTrackingMessage.orderStatus as OrderTrackingStatus) {
      case 'REGISTERED': {
        const statusSpecificFields = orderTrackingMessage as unknown as Omit<
          OrderTrackingRegisteredData,
          keyof OrderTrackingMessageContent
        >;
        return {
          ...result,
          estimatedDeliveryDate: dateToLocale(navigator, statusSpecificFields.estimatedDeliveryDate),
        } as OrderTrackingRegisteredData;
      }

      case 'SHIPPED': {
        const statusSpecificFields = orderTrackingMessage as unknown as Omit<
          OrderTrackingShippedData,
          keyof OrderTrackingMessageContent
        >;
        return {
          ...result,
          estimatedDeliveryDate: dateToLocale(navigator, statusSpecificFields.estimatedDeliveryDate),
        } as OrderTrackingShippedData;
      }

      case 'AVAILABLE_TO_WITHDRAW': {
        const statusSpecificFields = orderTrackingMessage as unknown as Omit<
          OrderTrackingAvailableToWithdrawData,
          keyof OrderTrackingMessageContent
        >;
        return {
          ...result,
          deliveryDate: dateToLocale(navigator, statusSpecificFields.deliveryDate),
        } as OrderTrackingAvailableToWithdrawData;
      }

      case 'COLLECTED': {
        const statusSpecificFields = orderTrackingMessage as unknown as Omit<
          OrderTrackingCollectedData,
          keyof OrderTrackingMessageContent
        >;
        return {
          ...result,
          deliveryDate: dateToLocale(navigator, statusSpecificFields.deliveryDate),
        } as OrderTrackingCollectedData;
      }

      default:
        this.logger.warn('This order tracking status is not implemented');
        return result;
    }
  }

  get isVisitor() {
    return (
      !this.message.author ||
      this.message.author.id === 'act_00000000-0000-0000-0000-000000000000' ||
      (this.widget.user && this.widget.user.id === this.message.author.id)
    );
  }

  get avatarUrl() {
    const url = this.message.author ? this.message.author.avatar : 'assets/leadAvatar.png';
    return `url("${url}")`;
  }

  get fullName(): string {
    const defaultName: string = 'Agent';
    const nonTranslatedNames = () => {
      if (this.message.author.firstName && this.message.author.lastName) {
        return `${this.message.author.firstName} ${this.message.author.lastName}`;
      }

      if (this.message.author.firstName) {
        return this.message.author.firstName;
      }

      if (this.message.author.lastName) {
        return this.message.author.lastName;
      }

      return '';
    };
    let fullName: string = '';

    if (!this.message.author) {
      fullName = defaultName;
    } else if (this.currentLanguage && this.message.author?.translatedNames?.length > 0) {
      const translatedFirstName: string | undefined = this.message.author.translatedNames.find(
        (tN) => tN.language === this.currentLanguage,
      )?.content?.firstName;
      const translatedLastName: string | undefined = this.message.author.translatedNames.find(
        (tN) => tN.language === this.currentLanguage,
      )?.content?.lastName;

      if (translatedFirstName && translatedLastName) {
        fullName = `${translatedFirstName} ${translatedLastName}`;
      } else if (translatedFirstName) {
        fullName = translatedFirstName;
      } else if (translatedLastName) {
        fullName = translatedLastName;
      } else {
        fullName = nonTranslatedNames();
      }
    } else {
      fullName = nonTranslatedNames();
    }

    return fullName ? fullName : defaultName;
  }

  public getButtonsStyle() {
    return this.displayService.chatStyle.buttonsStyle.buttons;
  }

  public getButtonsItemStyle(i: number) {
    if (i === this.hoverInd) {
      return this.displayService.chatStyle.buttonsStyle.hoveredItem;
    }
    return this.displayService.chatStyle.buttonsStyle.item;
  }

  public getButtonsItemContentStyle(i: number) {
    if (i === this.hoverInd) {
      return this.displayService.chatStyle.buttonsStyle.hoveredItemContent;
    }
    return this.displayService.chatStyle.buttonsStyle.itemContent;
  }

  public onImageLoad() {
    void this.widget.scrollDown();
  }

  public async processButtonMessage(item: ButtonContent) {
    if (this.widget.hideTextareaUntilButtonClick) {
      this.widget.hideTextareaUntilButtonClick = false;
    }

    switch (item.type) {
      case 'link':
        window.open(item.content, '_blank').focus();
        return;
      case 'reply':
        if (item.content) {
          await this.sendTextPayloadMessage(item.text, item.content);
        } else {
          await this.sendTextMessage(item.text);
        }
        break;
      default:
    }
  }

  public selectButtonMessageItem: ButtonContent | null = null;
  public validButtonMessageItem = false;
  public setSelectButtonMessageItem(change: Event) {
    const select = change.target as HTMLSelectElement;
    const ind = Number(select.value);
    this.selectButtonMessageItem = (this.message.content as ButtonsMessageContent)[ind] || null;
    if (this.selectButtonMessageItem) {
      this.validButtonMessageItem = true;
    }
  }
  public async selectButtonMessage() {
    if (this.selectButtonMessageItem) {
      await this.processButtonMessage(this.selectButtonMessageItem);
      this.selectButtonMessageItem = null;
      this.validButtonMessageItem = false;
    }
  }

  public async sendTextPayloadMessage(text: string, payload: any) {
    const textContent = this.sentMessageSanitizerService.sanitize(text);
    const now = Date.now();
    const socketMessage: Message = {
      author: this.widget.user,
      content: {
        text: textContent,
        payload,
      },
      sentDate: now,
      updatedDate: now,
      uuid: uuidV3(String(now), this.widget.session.id),
      type: MessageType.TEXT_PAYLOAD,
      delivered: false,
      noNotif: false,
      isWelcome: false,
      isEngage: false,
      popNotif: false,
      isButtonReply: true,
      isWelcomeEngageButtonReply: this.message.isEngage || this.message.isWelcome,
      isLastStep: false,
      schemaSatisfaction: null,
      showSchemaSatisfaction: false,
      webchatMessageId: null,
    };
    this.widget.pushMessage(socketMessage, false, false, true);
    await this.widget.setSelfCareActionButtonsDisplayedStatus(false);
    if (!this.widget.ghostMode) {
      await this.backendService.sendMessage(socketMessage, this.widget.parentPageUrl, this.widget.parentPageTitle);
    } else {
      this.widget.sendMessageGhostMode(socketMessage);
    }
    await this.widget.subscribeToSessionChange(this.widget.session.id, true);
  }

  public async sendTextMessage(text: string) {
    const content = this.sentMessageSanitizerService.sanitize(text);
    const now = Date.now();
    const socketMessage: Message = {
      author: this.widget.user,
      content,
      sentDate: now,
      updatedDate: now,
      uuid: uuidV3(String(now), this.widget.session.id),
      type: MessageType.TEXT,
      delivered: false,
      noNotif: false,
      isWelcome: false,
      isEngage: false,
      popNotif: false,
      isButtonReply: true,
      isWelcomeEngageButtonReply: this.message.isEngage || this.message.isWelcome,
      isLastStep: false,
      schemaSatisfaction: null,
      showSchemaSatisfaction: false,
      webchatMessageId: null,
    };
    this.widget.pushMessage(socketMessage, false, false, true);
    await this.widget.setSelfCareActionButtonsDisplayedStatus(false);
    if (!this.widget.ghostMode) {
      await this.backendService.sendMessage(socketMessage, this.widget.parentPageUrl, this.widget.parentPageTitle);
    } else {
      this.widget.sendMessageGhostMode(socketMessage);
    }
    await this.widget.subscribeToSessionChange(this.widget.session.id, true);
  }
  public get attachmentSrc() {
    const content = this.message.content as AttachmentMessageContent;
    switch (content.contentType) {
      case 'pdf':
        return 'assets/file-icon-pdf.png';
      case 'video':
        return 'movie-line';
      case null:
      default:
        return 'assets/file-icon-unknown.png';
    }
  }
  public isVideoMessageContent(content: MessageContent) {
    return !!(content && (content as AttachmentMessageContent).contentType === 'video');
  }
  public get messageContentAsAny() {
    return this.message.content as any;
  }
  public get isLongButton() {
    return this.message.type === 'buttons' && (this.message.content as ButtonsMessageContent).length > 5;
  }
  public get isVeryLongButton() {
    return this.message.type === 'buttons' && (this.message.content as ButtonsMessageContent).length > 10;
  }

  /**
   * Parse any text message content
   */
  private vHtmlCarousel: HtmlCarouselMessageContent = this.htmlCarouselService.content;
  public get htmlCarousel(): HtmlCarouselMessageContent {
    if (this.message.type === 'text' && !this.isVisitor) {
      const text = this.message.content as string;

      // Cache the Carousel and re-render only when changes occur.
      const hasChanged = this.htmlCarouselService.originalText !== text;
      if (hasChanged) {
        this.htmlCarouselService.originalText = text;
        try {
          this.htmlCarouselService.extractHtmlCarouselMessageContent();
        } catch (error) {
          this.logger.error('Error parsing html carousel with:', this.htmlCarouselService.originalText);
        }

        this.isHtmlCarousel = this.htmlCarouselService.content?.items?.length > 0;
      }
    }

    return this.vHtmlCarousel;
  }

  public getMessageContentStyle(
    type: MessageType = this.message.type,
    chatMode: 'chat' | 'form' = this.chatMode,
  ): {
    [key: string]: string | null;
  } {
    if (type === MessageType.TEXT || type === MessageType.TEXT_PAYLOAD) {
      return {
        border: 'none',
      };
    } else if (type === MessageType.IMAGE || type === MessageType.VIDEO) {
      return {
        width: 'calc(100% - 30px)',
      };
    } else if (type === MessageType.ORDER_TRACKING) {
      return {
        'aspect-ratio': chatMode === 'form' ? '3.5 / 1' : null,
      };
    }
    return {};
  }

  public getDisplayableMessageText(msg: MessageContent) {
    let text = msg as string;

    if (text && text.includes('<img')) {
      let doc: Document;
      try {
        doc = this.domParser.parseFromString(text, 'text/html');
      } catch (error) {
        this.logger.warn(`Can't parse HTML content from:`, text);
      }
      const src = doc.querySelector('img').getAttribute('src');
      return `<img style="width:100%" src="${src}" />`;
    }

    if (text && text.includes('<p>')) {
      text = text.replace(/<p>/g, '<p style="margin: 0">');
    }

    return text;
  }

  public showWebview(msgContent: MessageContent): void {
    this.openWebview.emit(msgContent as WebviewMessageContent);
  }

  public setSchemaSatisfaction(satisfaction: SchemaSatisfaction = null): void {
    const dataToSet: SchemaSatisfactionPayload = {
      messageId: this.message.webchatMessageId,
      previousSchemaSatisfaction: this.message.schemaSatisfaction || null,
      schemaSatisfaction: satisfaction,
    };

    this.schemaSatisfaction.emit(dataToSet);

    this.message.schemaSatisfaction = dataToSet.schemaSatisfaction;
  }
}
