import { Injectable } from '@angular/core';
import { interval } from 'rxjs';
import { defaultIfEmpty, map, takeWhile } from 'rxjs/operators';
import { LoggerService } from '../../services/logger/logger.service';

interface TypeParams {
  previous: string;
  text: string;
  targetWordsPerMinute: number;
  completed: boolean;
}

/**
 * Expressly "standalone" service, must be used only by only one component at a time.
 * Do not use with {providedIn: 'root'}.
 */
@Injectable()
export class TypewriterService {
  constructor(private logger: LoggerService) {}

  position = 0;

  /**
   * Generates an observable stream that simulates typing by incrementally emitting substrings of the target text.
   * Calculates the typing speed based on the provided words-per-minute rate.
   *
   * @param previous - The initial text, representing already typed characters to start from.
   * @param text - The full target text to type out in the simulation.
   * @param completed - Indicates whether typing should complete instantly.
   * @param targetWordsPerMinute - The target typing speed in words per minute.
   * @returns Observable emitting progressively typed substrings of the `text` until completed.
   */
  private type({ previous, text, completed, targetWordsPerMinute = 250 }: TypeParams) {
    const start = previous.length;
    const end = text.length;
    const totalWords = text.split(' ');
    const fixedFPS = 20; // Fixed frames per second for smooth typing simulation.
    const period = 1000 / fixedFPS;
    const targetWordsPerInterval = (targetWordsPerMinute / 60) * (period / 1000);
    return interval(period).pipe(
      map(() => {
        if (end > this.position) {
          this.position += targetWordsPerInterval;
          return totalWords.slice(0, Math.ceil(this.position)).join(' ');
        }
        return ''; // Can't use rxjs filter here it always return null whatever defaultIfEmpty is set or not.
      }),
      takeWhile(() => !(completed || start >= end)),
      defaultIfEmpty(text ?? ''),
    );
  }

  /**
   * Initiates the typewriting effect, concatenating any prior typed text with the
   * simulated typing observable for the target text.
   *
   * @param previous - The initial text to prepend to the typing simulation.
   * @param text - The full text to type out as part of the typewriting effect.
   * @param completed - A flag indicating if the typing effect should complete instantly.
   * @param targetWordsPerMinute - The desired typing speed in words per minute.
   * @returns Observable that emits strings representing the progressively typed text.
   */
  typeEffect(previous = '', text = '', completed = false, targetWordsPerMinute = 250) {
    return this.type({ previous, text, completed, targetWordsPerMinute });
  }
}
