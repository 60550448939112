import { HttpClient } from '@angular/common/http';
import { Injector, NgModule } from '@angular/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

export function httpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/');
}

@NgModule()
export class AppModule {
  constructor(private injector: Injector) {}
}
