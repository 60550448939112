import { Component, ElementRef, HostListener, Input, OnDestroy, ViewChild } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ExtendedModule } from '@ngbracket/ngx-layout/extended';
import { AngularRemixIconComponent } from 'angular-remix-icon';
import { FlexModule } from '@ngbracket/ngx-layout/flex';
import { NgIf, NgStyle, NgFor, NgSwitch, NgSwitchCase, NgClass } from '@angular/common';
import { jsonCopy, openUrl } from '../misc/utilities';
import { CarouselItem, extensionToMediaTypeMap, MediaType } from '../model/carousel.model';
import { DisplayService } from '../services/display.service';
import { SanitizeMessagePipe } from '../pipes/sanitize-message.pipe';
import { LoggerService } from '../services/logger/logger.service';

/** Delay at which window resize event will be taken into accout */
const DEBOUNCE_TIME = 250;

/**
 * Previously coded inline into the message component and refactored to become a "standalone" component.
 */
@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrl: './carousel.component.scss',
  standalone: true,
  imports: [
    NgIf,
    FlexModule,
    AngularRemixIconComponent,
    NgStyle,
    ExtendedModule,
    NgFor,
    NgSwitch,
    NgSwitchCase,
    NgClass,
    SanitizeMessagePipe,
  ],
})
export class CarouselComponent implements OnDestroy {
  /** The text message accompanying the Carousel where there is one. */
  @Input() message: string;

  private vCarouselItems: CarouselItem[];
  @Input()
  public get carouselItems(): CarouselItem[] {
    return this.vCarouselItems;
  }

  public set carouselItems(value: CarouselItem[]) {
    this.vCarouselItems = jsonCopy(value);
    if (this.carouselItems?.length) {
      this.carouselIndex = 0;
      this.setMediaTypes();
    }
  }

  @Input() isVisitor: boolean;
  @Input() isDelivered: boolean;

  private subs: Subscription[] = [];
  private resizeSubject: Subject<Event> = new Subject();
  public hoverInd = -1;
  public carouselItemSize: { width: number; height: number } = { width: null, height: null };
  public carouselIndex: number = -1;
  public viewOpenUrl = openUrl;
  private vCarouselMediaContainer: ElementRef<HTMLDivElement> = null;

  @ViewChild('mediaContainer', { static: false })
  public set carouselMediaContainer(container: ElementRef<HTMLDivElement>) {
    if (container?.nativeElement) {
      setTimeout(() => (this.vCarouselMediaContainer = container));
    }
  }
  public get carouselMediaContainer(): ElementRef<HTMLDivElement> {
    return this.vCarouselMediaContainer;
  }
  get currentCarouselItem(): CarouselItem {
    return this.carouselItems[this.carouselIndex];
  }
  constructor(
    private logger: LoggerService,
    public displayService: DisplayService,
  ) {
    this.subs.push(
      this.resizeSubject.pipe(debounceTime(DEBOUNCE_TIME)).subscribe(() => {
        this.carouselItemSizeOnResize();
      }),
    );
  }
  ngOnDestroy(): void {
    this.subs?.forEach?.((sub) => sub?.unsubscribe?.());
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.resizeSubject.next();
  }

  setMediaTypes() {
    for (const carouselItem of this.carouselItems) {
      const mediaType = this.inferMediaType(carouselItem.mediaUrl);
      carouselItem.mediaType = mediaType ?? 'image';
    }
  }

  public moveToCarouselItem(direction: 'left' | 'right'): number {
    if (!this.carouselItemSize.width && !this.carouselItemSize.height) {
      this.getCarouselItemSize();
    }

    const currentXScroll: number = this.carouselMediaContainer.nativeElement.scrollLeft;
    // The following could be improved by managing wrongly placed carousel items after the browser's window is resized
    const initialPositionIsValid: boolean = currentXScroll === 0 ? true : this.positionIsModulo(currentXScroll);

    if (this.carouselItemSize?.width > 0 && this.carouselItemSize?.height > 0) {
      if (this.carouselItems?.length > 0 && initialPositionIsValid) {
        const newPosition: number = this.createNewCarouselScrollPosition(direction, currentXScroll);

        if (this.positionIsModulo(newPosition)) {
          this.carouselMediaContainer.nativeElement.scrollTo({
            left: newPosition,
            behavior: 'smooth',
          });

          if (direction === 'left' && this.carouselIndex === 0) {
            this.carouselIndex = this.carouselItems.length - 1;
          } else if (direction === 'right' && this.carouselIndex === this.carouselItems.length - 1) {
            this.carouselIndex = 0;
          } else {
            this.carouselIndex = CarouselComponent.clamp(
              direction === 'left' ? (this.carouselIndex -= 1) : (this.carouselIndex += 1),
              0,
              this.carouselItems.length - 1,
            );
          }
        }
      }
    }

    return this.carouselIndex;
  }

  private getCarouselItemSize() {
    if (this.carouselItems?.length && this.carouselMediaContainer) {
      if (this.carouselMediaContainer?.nativeElement) {
        const carouselMediaContainerHeight: number =
          this.carouselMediaContainer.nativeElement.getBoundingClientRect().height;
        const carouselMediaContainerWidth: number =
          this.carouselMediaContainer.nativeElement.getBoundingClientRect().width;

        if (carouselMediaContainerHeight > 0 && carouselMediaContainerWidth > 0) {
          this.carouselItemSize.height = Math.trunc(carouselMediaContainerHeight);
          this.carouselItemSize.width = Math.trunc(carouselMediaContainerWidth);
        }
      }
    }
  }

  private tryMoveToCarouselEdge(direction: 'left' | 'right'): 'start' | 'end' | null {
    if (direction === 'left' && this.carouselIndex === 0) {
      return 'end';
    } else if (direction === 'right' && this.carouselIndex === this.carouselItems.length - 1) {
      return 'start';
    }
    return null;
  }

  private createNewCarouselScrollPosition(direction: 'left' | 'right', currentScrollPosition: number): number {
    if (!this.tryMoveToCarouselEdge(direction)) {
      return CarouselComponent.clamp(
        direction === 'left'
          ? currentScrollPosition - this.carouselItemSize.width
          : currentScrollPosition + this.carouselItemSize.width,
        0,
        currentScrollPosition + this.carouselItems.length * this.carouselItemSize.width,
      );
    } else if (this.tryMoveToCarouselEdge(direction) === 'start') {
      return 0;
    } else if (this.tryMoveToCarouselEdge(direction) === 'end') {
      return this.carouselItemSize.width * this.carouselItems.length;
    }

    return 0;
  }

  private carouselItemSizeOnResize() {
    this.getCarouselItemSize();
    this.carouselMediaContainer.nativeElement.scrollLeft = this.carouselIndex * this.carouselItemSize.width;
  }

  // Utils
  private static clamp(number: number, lower: number, upper: number): number {
    let value: number = number;

    if (number < lower) {
      value = lower;
    } else if (number > upper) {
      value = upper;
    }

    return value;
  }

  private positionIsModulo(position: number): boolean {
    return position % this.carouselItemSize.width === 0;
  }

  private inferMediaType(url: string): MediaType | undefined {
    let path = '';
    try {
      const urlInstance = new URL(url);
      path = urlInstance.pathname;
    } catch (error) {
      this.logger.info('Carousel', 'Trying to parse an invalid url:', url);
    }
    const extension = path.split('.').pop()?.toLowerCase().trim();
    if (extensionToMediaTypeMap[extension]) {
      return extensionToMediaTypeMap[extension];
    }
    return undefined;
  }
}
