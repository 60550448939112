import { Component, Input, Output, EventEmitter } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { AngularRemixIconComponent } from 'angular-remix-icon';
import { ExtendedModule } from '@ngbracket/ngx-layout/extended';
import { NgStyle } from '@angular/common';
import { FlexModule } from '@ngbracket/ngx-layout/flex';
import { SafeUrlPipe } from '../pipes/safe-url.pipe';
import { DisplayService } from '../services/display.service';

@Component({
  selector: 'app-webview',
  templateUrl: './webview.component.html',
  styleUrls: ['./webview.component.scss'],
  standalone: true,
  imports: [FlexModule, NgStyle, ExtendedModule, AngularRemixIconComponent, SafeUrlPipe],
})
export class WebviewComponent {
  @Output() hide = new EventEmitter<void>();
  @Input() urlToBeSanitized: string;

  public urlToRender: SafeResourceUrl;

  constructor(public displayService: DisplayService) {}

  public hideWebview(): void {
    this.hide.emit();
  }
}
