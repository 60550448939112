import { Injectable } from '@angular/core';
import { FrontappHandlerData } from '../model/Session';
import { ActorType, User } from '../model/user';
import { Message, MessageType, TextPayloadMessageContent, ButtonContent } from '../model/message';
import { WidgetComponent } from '../widget/widget.component';
import { BackendService } from './backend.service';
import { LoggerService } from './logger/logger.service';

@Injectable({
  providedIn: 'root',
})
export class RedirectFrontappService {
  public data: any;
  public initJson: any;
  public user: User = {
    email: '',
    firstName: 'Agent',
    lastName: 'Front',
    type: ActorType.THIRD_PARTY,
    id: 'act_00000000-0000-0000-0000-000000000000',
    avatar: 'assets/leadAvatar.png',
    customData: null,
  };

  public widget: WidgetComponent;
  public enabled = false;
  public mustSetVisitor: User | null = null;

  constructor(
    private logger: LoggerService,
    public backendService: BackendService,
  ) {}

  public async init(initJson: any, widget: WidgetComponent) {
    this.initJson = initJson;
    this.data = initJson.data;
    this.user.id = initJson.actorId;
    this.widget = widget;
    this.enabled = true;
  }

  public async onMessage(message: Message) {
    if (!this.enabled) {
      return null;
    }
    if (message.author.id === this.user.id) {
      // No need to transmit messages of Front agent
      return null;
    }
    if (message.author.id === this.widget.user.id) {
      // If message is from visitor
      // TODO: When non-text messages will be implemented
      if (!this.widget.ghostMode) {
        return this.backendService.sendMessageToFront(message, this.initJson);
      }
      this.logger.warn('RFS: tried to send message on ghost');
    }
    return null;
  }

  public onRhDataChanged(rhData: FrontappHandlerData) {}

  public hrefChanged(href: string, pageName: string) {}

  public async sendHistory(messages: Message[]) {
    const types = {
      lead: '🧑',
      visitor: '🧑',
      agent: '👨‍💼',
      system: '🤖',
      bot: '🤖',
      third_party: '👨‍💼',
    };
    const history = 'HISTORY:\n'.concat(
      messages
        .map((message) => {
          let content: string;
          let temp: any;
          switch (message.type) {
            case MessageType.TEXT:
            case MessageType.IMAGE:
              content = message.content as string;
              break;
            case MessageType.TEXT_PAYLOAD:
              temp = message.content as TextPayloadMessageContent;
              content = `${temp.text}(${temp.payload})`;
              break;
            case MessageType.BUTTONS:
              content = (message.content as ButtonContent[])
                .map((c) => {
                  return `[${c.text}](${c.type}:${c.content})`;
                })
                .join('|');
              break;
            case MessageType.QUICK_REPLIES:
              content = (message.content as string[]).join('|');
              break;
            default:
              content = '';
              break;
          }
          return `[${types[message.author.type]} ${User.getFullName(message.author) || ''}] ${content}`;
        })
        .join('\n\n'),
    );
    if (!this.widget.ghostMode) {
      return this.backendService.sendHistoryToFront(messages, this.initJson);
    }
    this.logger.warn('RFS: tried to send history on ghost');
  }

  public async setUser(user: User) {}

  public visitorTyping(isTyping: boolean) {}

  public async endRedirection() {}
}
