import { Component, ViewEncapsulation, ElementRef, Input, OnInit, OnDestroy } from '@angular/core';

import { NgStyle } from '@angular/common';
import { LoggerService } from '../services/logger/logger.service';
import { ModalService } from './modal.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'jw-modal',
  templateUrl: 'modal.component.html',
  styleUrls: ['modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [NgStyle],
})
export class ModalComponent implements OnInit, OnDestroy {
  @Input() id: string;
  @Input() additionalStyle: any = {};
  @Input() sticky: boolean = false;
  private element: HTMLElement;
  public isOpen: boolean = false;

  constructor(
    private logger: LoggerService,
    private modalService: ModalService,
    private el: ElementRef,
  ) {
    this.element = el.nativeElement;
  }

  ngOnInit(): void {
    // ensure id attribute exists
    if (!this.id) {
      this.logger.error('modal must have an id');
      return;
    }

    // move element to bottom of page (just before </body>)
    // so it can be displayed above everything else
    document.body.appendChild(this.element);

    // close modal on background click
    this.element.addEventListener('click', (el) => {
      if (!this.sticky && (el.target as HTMLElement).className === 'jw-modal') {
        this.close();
      }
    });

    // add self (this modal instance) to the modal service so it's accessible from controllers
    this.modalService.add(this);
  }

  // remove self from modal service when component is destroyed
  ngOnDestroy(): void {
    this.modalService.remove(this.id);
    this.element.remove();
  }

  // open modal
  public open(): void {
    this.isOpen = true;
    this.element.style.display = 'flex';
    document.body.classList.add('jw-modal-open');
  }

  // close modal
  public close(): void {
    this.isOpen = false;
    this.element.style.display = 'none';
    document.body.classList.remove('jw-modal-open');
  }
}
